<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_casos_soporte_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Casos Soporte
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-md-5">Usuario Respuesta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      class="form-control form-control-sm p-0 col-md-7"
                      v-model="user"
                      placeholder="Usuario"
                      label="name"
                      :options="listasForms.users"
                      @input="selectUser()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Línea Negocio</label>
                    <select
                      id=""
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.linea_negocio_id"
                    >
                      <option value="">Todos...</option>
                      <option
                        v-for="linea_negocio in listasForms.lineas_negocio"
                        :key="linea_negocio.id"
                        :value="linea_negocio.id"
                      >
                        {{ linea_negocio.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Asunto</label>
                    <select
                      id=""
                      class="form-control form-control-sm col-sm-7"
                      v-model="filtros.asunto"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="asunto in listasForms.asuntos"
                        :key="asunto.numeracion"
                        :value="asunto.numeracion"
                      >
                        {{ asunto.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_inicial"
                        :class="
                          $v.filtros.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Hora Inicial</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.hora_inicial"
                        v-mask="{
                          mask: '99:99:99',
                          hourFormat: '23',
                        }"
                        :class="
                          $v.filtros.hora_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechasForm"
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="fecha_final">Fecha final:</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_final"
                        :class="
                          $v.filtros.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechasForm"
                      />
                    </div>
                    <div div class="col-md-6">
                      <label for="hora_final">Hora Final:</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filtros.hora_final"
                        v-mask="{
                          mask: '99:99:99',
                          hourFormat: '23',
                        }"
                        :class="
                          $v.filtros.hora_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="numero"
                              v-model="form.id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="numeracion"
                              >Número Caso</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="via_comunicacion"
                              v-model="form.via_comunicacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="via_comunicacion"
                              >Vía Comunicación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="linea_negocio_id"
                              v-model="form.linea_negocio_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="linea_negocio_id"
                              >Línea Negocio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_caso"
                              v-model="form.tipo_caso"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="tipo_caso"
                              >Tipo Caso</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="user"
                              v-model="form.user"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="user"
                              >Usuario</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="asunto"
                              v-model="form.asunto"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="asunto"
                              >Asunto</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_solicitado"
                              v-model="form.fecha_solicitado"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_solicitado"
                              >Fecha Solicitado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_respuesta"
                              v-model="form.fecha_respuesta"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_respuesta"
                              >Fecha Repuesta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="descripcion"
                              v-model="form.descripcion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="descripcion"
                              >Descripción</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="respuesta"
                              v-model="form.respuesta"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="respuesta"
                              >Repuesta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tiempo"
                              v-model="form.tiempo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="tiempo"
                              >Tiempo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="!$v.filtros.$invalid"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../components/Loading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CasosSoporteExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      user: [],
      listasForms: {
        users: [],
        lineas_negocio: [],
      },
      filtros: {
        fecha_inicial: null,
        hora_inicial: null,
        fecha_final: null,
        hora_final: null,
        user_id: null,
        linea_negocio_id: null,
        asunto: null,
      },
      form: {
        id: true,
        via_comunicacion: true,
        linea_negocio_id: true,
        tipo_caso: true,
        user: true,
        asunto: true,
        fecha_solicitado: true,
        fecha_respuesta: true,
        descripcion: true,
        respuesta: true,
        tiempo: true,
        estado: true,
      },
    };
  },
  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      hora_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      hora_final: {
        required,
      },
    },
  },

  methods: {
    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getUsuarios() {
      axios.get("/api/admin/usuarios/lista").then((response) => {
        this.listasForms.users = response.data;
      });
    },

    getAsunto() {
      axios.get("/api/lista/162").then((response) => {
        this.listasForms.asuntos = response.data;
      });
    },

    selectUser() {
      this.filtros.user_id = null;
      if (this.user) {
        this.filtros.user_id = this.user.id;
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/casosSoporte/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    validaFechasForm() {      
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        let fecha_menor = new Date(this.filtros.fecha_inicial);
        let fecha_mayor = new Date(this.filtros.fecha_final);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {        
        let fechaMenor = new Date(this.filtros.fecha_inicial).getTime();
        let fechaMayor = new Date(this.filtros.fecha_final).getTime();
        var diff = fechaMayor - fechaMenor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    limpiarModal() {
      this.filtros = {
        fecha_inicial: null,
        hora_inicial: null,
        fecha_final: null,
        hora_final: null,
        user_id: null,
        linea_negocio_id: null,
        asunto: null,
      };
      this.user = [];
    },
  },

  mounted() {
    this.getLineasNegocio();
    this.getUsuarios();
    this.getAsunto();
  },
};
</script>
